import React, { Component } from "react";
import styles from '../styles/Tabs.module.css';

class Tabs extends Component {
  state = {
    selected: this.props.selected || 0
  };

  
  handleChange(index) {
    this.setState({ selected: index }, () => {
      if (this.props.onChange) {
        this.props.onChange(index);
      }
    });
  }
  
  render() {
    const tabsStyle = this.props.tabsStyle || {};
    const ulStyle = this.props.ulStyle || {};

    return (
      <div className={styles["tabs"]} style={tabsStyle}>
        <ul className={styles["tabs-ul"]} style={ulStyle}>
          {this.props.children.map((elem, index) => {
            let style = index === this.state.selected ? "tab-li-selected" : "tab-li";
            console.log(this.state.selected);
            return (
              <li
                key={index}
                className={styles[style]}
                onClick={() => this.handleChange(index)}
              >
                {elem.props.title}
              </li>
            );
          })}
        </ul>
        <div className="tab">{this.props.children[this.state.selected]}</div>
      </div>
    );
  }
}

export default Tabs;