import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/TestSwitcher.module.css';

const TestSwitcher = ({ activeTest }) => {
    const navigate = useNavigate();

    const getButtonClass = (testName) => {
        return activeTest === testName ? styles["test-button-active"] : styles["test-button"];
    };

    return (
        <div className={styles["test-switcher"]}>
            <button className={getButtonClass('culturefit')} onClick={() => navigate('/culturefit')}>Intérêts</button>
            <button className={getButtonClass('softskills')} onClick={() => navigate('/softskills')}>Qualités</button>
            <button className={getButtonClass('hardskills')} onClick={() => navigate('/hardskills')}>Compétences</button>
        </div>
    );
};

export default TestSwitcher;