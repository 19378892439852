import React, { useState, useEffect, useCallback } from 'react';
import { getSessionToken } from '../utils/Auth';
import ExperiencesModal from './ExperiencesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import styles from '../styles/List.module.css';

const ExperienceList = (refreshTrigger=null) => {
    const [experiences, setExperiences] = useState([]);
    const sessionkey = getSessionToken();

    const [expanded, setExpanded] = useState(null);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const [currentExperience, setCurrentExperience] = useState(null);

    const toggleExpand = (experienceId) => {
        if (expanded === experienceId) {
            setExpanded(null);  // Ferme le panneau si déjà ouvert
        } else {
            setExpanded(experienceId);  // Ouvre le panneau pour le job sélectionné
        }
    };

    const fetchExperiences = useCallback(async () => {
        if (sessionkey) {
            try {
                const response = await fetch('https://dev.api.wanteed-job.com/experience/get', {
                    method: 'GET',
                    headers: {
                        'session-key': sessionkey,
                    },
                });
                const data = await response.json();
                setExperiences(data);
            } catch (error) {
                console.error('Error fetching experiences:', error);
            }
        } else {
            setExperiences([]);
        }
    }, [sessionkey]);

    useEffect(() => {
        fetchExperiences();
    }, [fetchExperiences]);

    const handleEdit = (experienceId) => {
        let experience = experiences.find(exp => exp.experience_id === experienceId);
        setCurrentExperience({ experience: experience, hardskills: [] });
        setIsModalEditOpen(true);
    };

    const closeModal = () => {
        setIsModalEditOpen(false);
        setIsModalAddOpen(false);
        fetchExperiences();
    };

    useEffect(() => {
        if (refreshTrigger) {
            fetchExperiences();
        }
    }, [refreshTrigger, fetchExperiences]);

    const handleDelete = async (experienceId) => {
        if (!sessionkey) {
            // If the user is not connected, remove the experience from local storage
            const storedExperiences = JSON.parse(localStorage.getItem('experiences')) || [];
            const updatedExperiences = storedExperiences.filter(exp => exp.experience_id !== experienceId);
            localStorage.setItem('experiences', JSON.stringify(updatedExperiences));
            setExperiences(updatedExperiences);
        } else {
            // If the user is connected, send the delete request to the API
            try {
                const response = await fetch('https://dev.api.wanteed-job.com/experience/remove', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'session-key': sessionkey
                    },
                    body: JSON.stringify({ experience_id: experienceId })
                });
                if (response.ok) {
                    fetchExperiences();
                } else {
                    console.error('Failed to delete experience');
                }
            } catch (error) {
                console.error('Error deleting experience:', error);
            }
        }
    };

    function formatTimeInMonths(months) {
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;
        if (years > 0 && remainingMonths > 0) {
            return `${years} an${years > 1 ? 's' : ''} et ${remainingMonths} mois`;
        }
        if (years > 0) {
            return `${years} an${years > 1 ? 's' : ''}`;
        }
        return `${months} mois`;
    }

    const handleExpand = (experienceId) => {
        if (expanded === experienceId) {
            setExpanded(null);
        } else {
            setExpanded(experienceId);
        }
    }

    const addExperienceTemplate = {
        title: '',
        duration: 0,
        description: '',
        company: '',
        hardskills: [],
        study: false,
    }

    return (
        <div className={styles["list"]}>

            {isModalEditOpen && <ExperiencesModal experiences={[currentExperience]} onClose={closeModal} isEdit={true} />}
            {isModalAddOpen && <ExperiencesModal experiences={[addExperienceTemplate]} onClose={closeModal} />}
            {experiences.length === 0 && <p>Vous n'avez pas encore ajouté d'expérience.</p>}
            {experiences.map(experience => (
                <div key={experience.experience_id} className={styles["experience-item"]} onClick={() => handleExpand(experience.experience_id)}>
                    <div className={styles["experience-header"]}>
                        <h3>{experience.title} - {experience.company}</h3>
                        <div className={styles["buttons"]}>
                            <button className={styles["view-button"]} onClick={() => toggleExpand(experience.experience_id)}>
                                {
                                    // If the experience is expanded, display the 'Closed eye' icon, otherwise display the 'Open eye' icon
                                    expanded === experience.experience_id
                                        ? <FontAwesomeIcon icon={faEyeSlash} />
                                        : <FontAwesomeIcon icon={faEye} />
                                }
                            </button>
                            <button className={styles["edit-button"]} onClick={() => handleEdit(experience.experience_id)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button className={styles["delete-button"]} onClick={(e) => { e.stopPropagation(); handleDelete(experience.experience_id); }}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    </div>
                    <div className={styles["hard-skills"]}>
                        {experience.hardskills && experience.hardskills.map((skill, index) => (
                            <span key={index} className={styles["skill-tag"]}>#{skill}</span>
                        ))}
                    </div>
                    {expanded === experience.experience_id && (
                        <div className={styles["expanded-content"]}>
                            <p>{experience.description}</p>
                            <br />
                            <p><strong>Durée :</strong> {formatTimeInMonths(experience.duration)}</p>
                        </div>
                    )}
                </div>
            ))}
            <div>
                <button onClick={() => setIsModalAddOpen(true)}>Ajouter une expérience</button>
            </div>
        </div>
    );
};

export default ExperienceList;
