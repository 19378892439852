import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSessionToken } from '../utils/Auth';
import Button from './Button';
import styles from '../styles/Candidats.module.css';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from './Modal';

// Wanteed logo to be displayed for candidates that come from Wanteed
//import wanteedLogo from '../assets/logo.png';


const Candidats = (jobData) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortCriteria, setSortCriteria] = useState('global');
    const [sortOrder, setSortOrder] = useState('descending');
    const sessionToken = getSessionToken();
    const [isLoading, setIsLoading] = useState(true);
    const [candidates, setCandidates] = useState([]);
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const [candidateOrigins, setcandidateOrigins] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [contactInfo, setContactInfo] = useState({ phone: '', email: '', linkedin: '' });

    const navigate = useNavigate();

    const job_id = jobData.jobData.job_id;
    const company_id = jobData.jobData.company_id;

    useEffect(() => {
        const fetchAndSetRecommendedCandidates = async () => {
            const response = await fetch(`https://dev.api.wanteed-job.com/candidate/get?job_id=${job_id}&company_id=${company_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': sessionToken
                }
            });
            const candidates = await response.json();

            console.log("candidates", candidates);
            setCandidates(candidates);
            setFilteredCandidates(candidates);
            setIsLoading(false);
        }
        fetchAndSetRecommendedCandidates();
    }, [job_id, company_id, sessionToken]);

    useEffect(() => {
        fetch(`https://dev.api.wanteed-job.com/candidate/get-job-stats?company_id=${company_id}&job_id=${job_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'session-key': sessionToken,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                let candidateOrigins = [];

                for (let i = 0; i < data.length; i++) {
                    if (!candidateOrigins.includes(data[i].origin))
                        candidateOrigins.push(data[i].origin);
                }
                setcandidateOrigins(candidateOrigins);
            })
    }, [company_id, job_id, sessionToken]);

    const handleViewCandidate = (candidateId) => {
        navigate(`/candidatepersona/${candidateId}`, {
            state: { company_id, job_id }
        });
    };

    const filterAndSortCandidates = useCallback(() => {
        let localCandidates = [...candidates];

        // Filtrer en fonction du terme de recherche
        if (searchTerm) {
            localCandidates = candidates.filter(candidate =>
                candidate.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                candidate.last_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Trier les candidats selon les critères et l'ordre
        localCandidates.sort((a, b) => {
            let comparison = 0;
            switch (sortCriteria) {
                case 'global':
                    comparison = a.global_similarity - b.global_similarity;
                    break;
                case 'favorite':
                    comparison = a.favorite - b.favorite;
                    break;
                case 'hardskill':
                    comparison = a.hardskill_similarity - b.hardskill_similarity;
                    break;
                case 'culture':
                    comparison = a.culture_similarity - b.culture_similarity;
                    break;
                case 'softskill':
                    comparison = a.softskill_similarity - b.softskill_similarity;
                    break;
                case 'mbti':
                    comparison = a.mbti_similarity - b.mbti_similarity;
                    break;
                default:
                    break;
            }

            if (sortOrder === 'ascending') {
                return comparison;
            } else {
                return -comparison;
            }
        });

        setFilteredCandidates(localCandidates);
    }, [searchTerm, sortCriteria, sortOrder, candidates]);


    // Handle changes in search input
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Handle changes in sort criteria
    const handleSortCriteriaChange = (e) => {
        setSortCriteria(e.target.value);
    };

    // Handle changes in sort order
    const handleSortOrderChange = (e) => {
        setSortOrder(e.target.value);
    };

    // Trigger filtering and sorting when search term, sort criteria, or sort order changes
    useEffect(() => {
        filterAndSortCandidates();
    }, [searchTerm, sortCriteria, sortOrder, filterAndSortCandidates]);

    const colorsList = [
        "#FF9800", // Orange
        "#808080", // Gray
        "#4CAF50", // Gray
        "#007bff", // Primary
        "#FF4500", // OrangeRed
        "#FF6347", // Tomato
        "#FF1493", // DeepPink
        "#00CED1", // DarkTurquoise
        "#FFA500", // Orange
        "#1E90FF", // DodgerBlue
        "#32CD32", // LimeGreen
        "#7FFF00", // Chartreuse
        "#DC143C", // Crimson
        "#228B22", // ForestGreen
        "#00FF00", // Lime
        "#FF69B4", // HotPink
        "#9932CC", // DarkOrchid
        "#4169E1", // RoyalBlue
        "#8B0000", // DarkRed
        "#FF00FF", // Magenta
        "#ADFF2F", // GreenYellow
        "#00FA9A", // MediumSpringGreen
        "#00FF7F", // SpringGreen
        "#808080", // Gray
        "#B22222", // FireBrick
        "#7B68EE", // MediumSlateBlue
        "#8A2BE2", // BlueViolet
        "#FF8C00", // DarkOrange
    ];

    const getSkillColorClass = (percentage) => {
        if (percentage >= 100) return styles["blue-text"];
        return styles["grey-text"];
    };

    // Ajouter cette fonction dans votre composant Candidats
    const handleFavoriteCandidate = async (candidateId, isFavorite) => {
        const url = isFavorite
            ? 'https://dev.api.wanteed-job.com/candidate/remove-favorite'
            : 'https://dev.api.wanteed-job.com/candidate/favorite';

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'session-key': sessionToken
            },
            body: JSON.stringify({
                company_id: company_id,
                job_id: job_id,
                candidate_id: candidateId
            })
        });

        if (response.ok) {
            setCandidates(prevCandidates =>
                prevCandidates.map(candidate =>
                    candidate.user_id === candidateId
                        ? { ...candidate, favorite: !isFavorite }
                        : candidate
                )
            );
        } else {
            console.error(`Failed to ${isFavorite ? 'remove' : 'add'} candidate ${candidateId} ${isFavorite ? 'from' : 'to'} favorites`);
        }
    };

    const handleContactCandidate = (candidate) => {
        setContactInfo({
            phone: candidate.phone,
            email: candidate.email,
            linkedin: candidate.linkedin
        });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        isLoading ? <div>Chargement...</div> :
            <div>
                <div className={styles["filter-bar"]}>
                    <input
                        type="text"
                        placeholder="Rechercher un candidat..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className={styles["filter-bar"]}>
                    <select className={styles["filter-select"]} value={sortCriteria} onChange={handleSortCriteriaChange}>
                        <option value="global">Pertinence</option>
                        <option value="favorite">Favoris</option>
                        <option value="hardskill">Compétences</option>
                        <option value="culture">Intérêts</option>
                        <option value="softskill">Qualités</option>
                        {/* <option value="mbti">Capacité d'integration</option> */}
                    </select>
                    <select className={styles["filter-select"]} value={sortOrder} onChange={handleSortOrderChange}>
                        <option value="ascending">Ascendant</option>
                        <option value="descending">Descendant</option>
                    </select>
                </div>

                <div className={styles["origin-legend"]}>
                    {candidateOrigins.map((origin, index) => (
                        <div key={index} className={styles["legend-item"]}>
                            <span
                                className={styles["legend-color"]}
                                style={{ backgroundColor: colorsList[index % colorsList.length] }}
                            ></span>
                            <span>{origin}</span>
                        </div>
                    ))}
                </div>

                <div className={styles["candidate-grid"]}>
                    {filteredCandidates.map(candidate => (
                        <div key={candidate.user_id} className={styles["candidate-card"]}>
                            <div
                                className={styles["origin-flag"]}
                                style={{ backgroundColor: colorsList[candidateOrigins.indexOf(candidate.origin) % colorsList.length] }}
                            >
                                {candidate.origin} <br /> {new Date(candidate.added_time).toLocaleDateString()}
                            </div>

                            <div className={`${styles["heart-favorite"]} ${candidate.favorite ? styles["active"] : ""}`} onClick={() => handleFavoriteCandidate(candidate.user_id, candidate.favorite)}>
                                <i className="fas fa-heart">
                                    <FontAwesomeIcon icon={faStar} />
                                </i>
                            </div>

                            <div className={styles["global-score-circle"]}>
                                <div className={styles["circle-background"]}>
                                    <div
                                        className={styles["circle-progress"]}
                                        style={{
                                            '--progress': `${candidate.global_similarity}%`
                                        }}
                                    />
                                    <div className={styles["circle-content"]}>
                                        {candidate.global_similarity.toPrecision(3)}%
                                    </div>
                                </div>
                            </div>
                            <h3 className={styles["candidate-name"]}>{candidate.first_name + " " + candidate.last_name}</h3>
                            <div className={styles["relevance-score"]}>
                                <div className={styles["candidate-grades"]}>
                                    <p>
                                        <span>Compétences :</span>
                                        <span className={getSkillColorClass(candidate.hardskill_similarity)}>
                                            {candidate.hardskill_similarity.toFixed(2)}%
                                        </span>
                                    </p>
                                    <p>
                                        <span>Intérêts :</span>
                                        <span className={getSkillColorClass(candidate.culture_similarity)}>
                                            {candidate.culture_similarity.toFixed(2)}%
                                        </span>
                                    </p>
                                    <p>
                                        <span>Qualités :</span>
                                        <span className={getSkillColorClass(candidate.softskill_similarity)}>
                                            {candidate.softskill_similarity.toFixed(2)}%
                                        </span>
                                    </p>
                                    {/* <p>
                                        <span>Team fit :</span>
                                        <span className={getSkillColorClass(candidate.mbti_similarity)}>
                                            {candidate.mbti_similarity.toFixed(2)}%
                                        </span>
                                    </p> */}
                                </div>
                            </div>
                            <Button className={styles["view-candidate-btn"]} text={"Voir le candidat"} onClick={() => handleViewCandidate(candidate.user_id)} />
                            <Button className={styles["contact-candidate-btn"]} text={"Contacter"} onClick={() => handleContactCandidate(candidate)} />
                        </div>
                    ))}
                </div>

                <Modal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    contentLabel="Contact Information"
                    className={styles["modal"]}
                    overlayClassName={styles["overlay"]}
                >
                    <h2 className={styles["modal-title"]}>Contact Information</h2>
                    <div className={styles["modal-divider"]}>
                        <div className={styles["modal-subdivider"]}><strong>Telephone:</strong> {contactInfo.phone}</div>
                        <div className={styles["modal-subdivider"]}><strong>Email:</strong> {contactInfo.email}</div>
                        <div className={styles["modal-subdivider"]}><strong>LinkedIn:</strong> <a href={contactInfo.linkedin} target="_blank" rel="noopener noreferrer">{contactInfo.linkedin}</a></div>
                    </div>
                </Modal>
            </div>
    );
};

export default Candidats;
