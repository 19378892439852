// src/components/IconButton.js
import React from 'react';
import { FaHeart, FaEllipsisV, FaFlag } from 'react-icons/fa';
import styles from '../styles/IconButton.module.css';

function IconButton({ icon, onClick, className = '', color = 'grey' }) {
    const renderIcon = () => {
        switch (icon) {
            case 'heart':
                return <FaHeart color={color} />;
            case 'ellipsis':
                return <FaEllipsisV color={color} />;
            case 'flag':
                return <FaFlag color={color} />
            default:
                return null;
        }
    };

    return (
        <button className={`${styles['icon-button']} ${className}`} onClick={onClick}>
            {renderIcon()}
        </button>
    );
}

export default IconButton;
