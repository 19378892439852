import React, { useState } from 'react';
import styles from '../styles/ExperienceModal.module.css';
import ExperienceForm from './ExperienceForm';

const ExperiencesModal = ({ experiences, onClose, isEdit = false }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(true);

    console.log(onClose);

    const handleValidate = () => {
        if (currentIndex < experiences.length - 1) {
            setCurrentIndex(currentIndex + 1);
        } else {
            console.log("Called onClose");
            setIsOpen(false);
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <>
            {experiences && experiences.length > 0 && (
                <div className={styles["modal-overlay"]} onClick={onClose}>
                    <div className={styles["modal-content"]} onClick={(e) => e.stopPropagation()}>
                        <button className={styles["modal-close"]} onClick={onClose}>×</button>
                        <h2>
                            {isEdit
                                ? 'Modifier'
                                : `Ajouter ${experiences.length > 1
                                    ? `des expériences ${currentIndex + 1}/${experiences.length}`
                                    : 'une expérience'}`}
                        </h2>
                        <ExperienceForm
                            experience={experiences[currentIndex]}
                            onValidate={handleValidate}
                            isEdit={isEdit}
                            isLast={currentIndex === experiences.length - 1}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default ExperiencesModal;
