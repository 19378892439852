import React from "react";
import GenericToggleButton from "../components/GenericToggleButton";
import PersonaPage from "./PersonaPage";
import ExperiencePage from "./ExperiencePage";
import Profile from "../components/Profile";

const ProfilePage = () => {
    const [view, setView] = React.useState("profil");
    const buttons = [
        { view: "profil", label: "Profil" },
        { view: "experience", label: "Experience" },
        { view: "quality", label: "Qualité" }
    ];

    return (
        <div>
            <GenericToggleButton view={view} setView={setView} buttons={buttons} />
            {view === "profil" && <Profile />}
            {view === "experience" && <ExperiencePage />}
            {view === "quality" && <PersonaPage />}
        </div>
    );
}

export default ProfilePage;