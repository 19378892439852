// src/pages/Register.js
import React, { useState } from "react";
import styles from "../styles/Register.module.css";
import { useNavigate } from "react-router-dom";
import { getSessionToken } from "../utils/Auth";
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Register() {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [terms, setTerms] = useState(false);
    const [alert, setAlert] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [linkedInProfile, setLinkedInProfile] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const navigate = useNavigate();

    const handleRegister = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setAlert("Passwords do not match");
            return;
        }

        if (!terms) {
            setAlert("Please accept the terms and conditions");
            return;
        }

        let headers = {
            'Content-Type': 'application/json',
        };

        if (localStorage.getItem('dummyRegister') === 'true') {
            let sessionKey = getSessionToken();
            localStorage.removeItem('dummyRegister');
            headers['session-key'] = sessionKey;
        }

        fetch('https://dev.api.wanteed-job.com/credential/register', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ 
                email: email, 
                password: password, 
                first_name: firstName, 
                last_name: lastName,
                phone: phoneNumber,
                linkedin: linkedInProfile
            }),
        })
            .then(data => {
                if (!data.ok) {
                    setAlert("Email already exists");
                } else {
                    navigate('/validate?email=' + email);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div className={styles["registerContainer"]}>
            <div className={styles["registerForm"]}>
                <form onSubmit={handleRegister}>
                    <h1>Inscription</h1>
                    <p>Créez votre compte Wanteed !</p>
                    <div className={styles["rowContainer"]} style={{ marginTop: "50px" }}>
                        <div className={styles["inputRow"]} style={{ width: "48%" }}>
                            <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                className="inputGroup"
                                placeholder="Prénom"
                                value={firstName}
                                // remove alert when typing and set the value
                                onChange={(e) => {
                                    setAlert("");
                                    setFirstName(e.target.value);
                                }}
                            /><br />
                        </div>
                        <div className={styles["inputRow"]} style={{ width: "48%" }}>
                            <input
                                type="text"
                                id="last_name"
                                name="last_name"
                                className="inputGroup"
                                placeholder="Nom"
                                value={lastName}
                                onChange={(e) => {
                                    setAlert("");
                                    setLastName(e.target.value);
                                }}
                            /><br />
                        </div>
                    </div>
                    <div className={styles["inputRow"]}>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="inputGroup"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => {
                                setAlert("");
                                setEmail(e.target.value);
                            }}
                        /><br />
                    </div>
                    <div className={styles["inputRow"]}>
                        <input
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            className="inputGroup"
                            placeholder="Numéro de téléphone"
                            value={phoneNumber}
                            onChange={(e) => {
                                setAlert("");
                                setPhoneNumber(e.target.value);
                            }}
                        /><br />
                    </div>
                    <div className={styles["inputRow"]}>
                        <input
                            type="text"
                            id="linkedInProfile"
                            name="linkedInProfile"
                            className="inputGroup"
                            placeholder="Profil LinkedIn"
                            value={linkedInProfile}
                            onChange={(e) => {
                                setAlert("");
                                setLinkedInProfile(e.target.value);
                            }}
                        /><br />
                    </div>
                    <div className={styles["inputRow"]}>
                        <div className={styles["inputContainer"]}>
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                className="inputGroup"
                                placeholder="Mot de passe"
                                value={password}
                                onChange={(e) => {
                                    setAlert("");
                                    setPassword(e.target.value);
                                }}
                            />
                                 <i
                                className={styles["eyeIcon"]}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                            </i>
                        </div>
                        <br />
                    </div>
                    <div className={styles["inputRow"]}>
                        <div className={styles["inputContainer"]}>
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                id="confirmPassword"
                                name="confirmPassword"
                                className="inputGroup"
                                placeholder="Confirmer le mot de passe"
                                value={confirmPassword}
                                onChange={(e) => {
                                    setAlert("");
                                    setConfirmPassword(e.target.value);
                                }}
                            />
                            <span
                                className={styles["eyeIcon"]}
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                {showConfirmPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                            </span>
                        </div>
                        <br />
                    </div>
                    
                    <div className={styles["checkboxRow"]}>
                        <input type="checkbox" id="terms" name="terms" value={terms} onChange={(e) => {
                            setAlert("");
                            setTerms(e.target.checked);
                        }} />
                        <label htmlFor="terms">J'accepte les <a href="/terms">conditions générales</a> et la <a href="/privacy">politique de confidentialité</a></label>
                    </div>
                    <div className={styles["alert"]}>
                        {alert}
                    </div>
                    <button type="submit">S'identifier</button>
                    <p>Déjà un compte ? <a href="/login">Se connecter</a></p>
                </form>
            </div>
        </div>
    );
}

export default Register;
