// src/components/JobCard.js
import React, { useState } from 'react';
import { getSessionToken } from '../utils/Auth';
import IconButton from './IconButton';
import styles from "../styles/JobCard.module.css";

function JobCard({ id, title, company, date, location, type, description, salary, link, fav, userSimilarJob, candidated }) {
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
    const token = getSessionToken();

    const [favorite, setFavorite] = useState(fav === 'true');
    const [candidate, setCandidate] = useState(candidated === 'true');

    const toggleDescription = () => {
        setIsDescriptionVisible(!isDescriptionVisible);
    };

    let formattedDescription = description.replace(/\n/g, '<br>');
    formattedDescription += '<br><br>Salaire : ' + parseInt(salary) + ' € / An';

    const likeOffer = async (id) => {
        const endpoint = favorite
            ? 'remove-favorite'
            : 'add-favorite';
        const method = favorite
            ? 'DELETE'
            : 'POST';

        try {
            const response = await fetch(`https://dev.api.wanteed-job.com/job/${endpoint}?job_id=${id}`, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                }
            });

            if (!response.ok) {
                return console.error(`Failed to ${favorite ? 'remove like' : 'like'} offer ${id}. Status: ${response.status}`);
            }

            setFavorite(!favorite);
        } catch (error) {
            console.error(`An error occurred while ${favorite ? 'unliking' : 'liking'} offer ${id}:`, error);
        }
    };

    const applyJob = async (id) => {
        try {
            if (!token) {
                window.location.href = '/login';
            }

            if (candidate) {
                return console.error(`Failed to apply to offer ${id}. You already applied to this offer.`);
            }

            const response = await fetch(`https://dev.api.wanteed-job.com/candidate/add-wanteed`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                },
                body: JSON.stringify({ job_id: id })
            });

            if (!response.ok) {
                return console.error(`Failed to apply to offer ${id}. Status: ${response.status}`);
            }

            setCandidate(true);

        } catch (error) {
            console.error(`An error occurred while applying to offer ${id}:`, error);
        }
    }

    return (
        <div
            className={styles["job-card-container"]}
            onClick={toggleDescription}
        >
            <div className={`${styles["job-card"]} ${isDescriptionVisible ? styles["job-card-selected"] : ""}`}>
                <h3 style={{ width: "20%" }}>{title}</h3>
                <h3>{type}</h3>
                <h3>{company}</h3>
                <h3>{location}</h3>
                <p>{date}</p>
                {candidate ? (<p className={styles["alreadyApplyText"]}>déjà candidaté</p>)
                    : (!token ? (<p className={styles["alreadyApplyText"]}>connectez-vous pour postuler</p>) :
                    (<div className={styles["global-score-circle"]}>
                        <div className={styles["circle-background"]}>
                            <div
                                className={styles["circle-progress"]}
                                style={{
                                    '--progress': `${userSimilarJob}%`
                                }}
                            />
                            <div className={styles["circle-content"]}>
                                {userSimilarJob.toFixed(2)}%
                            </div>
                        </div>
                    </div>))}
                { token &&
                <div className={styles["job-card-actions"]}>
                    <IconButton icon="heart" onClick={(e) => { e.stopPropagation(); likeOffer(id); }} color={favorite ? 'red' : 'grey'} />
                </div>}
            </div>
            {isDescriptionVisible && (
                <div className={styles["job-card-description"]}>
                    <p dangerouslySetInnerHTML={{ __html: formattedDescription }} />
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            applyJob(id);
                        }}
                        className={candidate ? styles["alreadyApply"] : ""}
                    >
                        {candidate ? "Déjà candidaté" : "Postuler"}
                    </button>
                </div>
            )}
        </div>
    );
}

export default JobCard;
