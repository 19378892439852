// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import CookieConsent from './components/CookieConsent';
import HomePage from './pages/HomePage';
import Intro from './pages/Intro';
import Invite from './pages/Invite';
import Jobs from './pages/Jobs';
import Login from './pages/Login';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPasswordPage';
import QuestionnaireMBTI from './pages/QuestionnaireMBTI';
import SoftSkillTest from './pages/SoftSkillTest';
import ProtectedRoute from './components/ProtectedRoute';
import HardSkillsPage from './pages/HardSkillsPage';
import CultureFitPage from './pages/CultureFitPage';
import PersonaPage from './pages/PersonaPage';
import ProfilePage from './pages/ProfilePage';
import EditProfile from './pages/EditProfile';
import AdminCompanyPage from './pages/AdminCompanyPage';
import ViewJob from './pages/ViewJob';
import HomePageEnterprise from './pages/HomePageEnterprise';
import CandidatePersonaPage from './pages/CandidatePersonaPage';
import ValidateAccountPage from './pages/ValidateAccountPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <CookieConsent />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/validate" element={<ValidateAccountPage />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/invite" element={<Invite />} />
        <Route path="/intro" element={<Intro />} />
        <Route path="/teamfit" element={<QuestionnaireMBTI />} />
        <Route path="/softskills" element={<SoftSkillTest />} />
        <Route path="/hardskills" element={<HardSkillsPage />} />
        <Route path="/culturefit" element={<CultureFitPage />} />
        <Route path="/persona" element={
          <ProtectedRoute redirectTo="/login">
            <PersonaPage />
          </ProtectedRoute>
        } />
        <Route path="/profile" element={
          <ProtectedRoute redirectTo="/login">
            <ProfilePage />
          </ProtectedRoute>
        } />
        <Route path="/editprofile" element={
          <ProtectedRoute redirectTo="/login">
            <EditProfile />
          </ProtectedRoute>
        } />
        <Route path="/admin" element={
          <ProtectedRoute redirectTo="/login">
            <AdminCompanyPage />
          </ProtectedRoute>
        } />
        <Route path="/admin/job" element={
          <ProtectedRoute redirectTo="/login">
            <ViewJob />
          </ProtectedRoute>
        } />
        <Route path="/candidatepersona/:id" element={
          <ProtectedRoute redirectTo="/login">
            <CandidatePersonaPage />
          </ProtectedRoute>
        } />
        <Route path="/company" element={<HomePageEnterprise />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/tos" element={<TermsOfService />} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;