// src/Jobs.js
import React, { useState, useEffect, useCallback } from "react";
import JobCard from "../components/JobCard";
import styles from "../styles/Jobs.module.css";
import Tabs from '../components/Tabs';
import Panel from '../components/Panel';
import { getSessionToken } from '../utils/Auth';

function Jobs() {
    const token = getSessionToken();
    const dummy = localStorage.getItem('dummyRegister');
    if (!token || dummy === 'true') {
        window.location.href = '/culturefit';
    }

    const [activeTab, setActiveTab] = useState('available');
    const [nextButton, setNextButton] = useState(false);

    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [jobType, setJobType] = useState('all');
    const [labels, setLabels] = useState({});
    const [domains, setDomains] = useState({});
    const [categories, setCategories] = useState([]);

    const [locationCity, setLocationCity] = useState('all');
    const [locationCountry, setLocationCountry] = useState('all');
    const [company, setCompany] = useState('all');
    const [domain, setDomain] = useState('all');
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(10);

    const [filter, setfilter] = useState('high');
    const [searchTerm, setSearchTerm] = useState('');

    const getIndividualJob = useCallback(async (jobID) => {
        try {
            let headers = {
                'Content-Type': 'application/json',
            };

            if (token) {
                headers['session-key'] = token;
            }

            const response = await fetch(
                `https://dev.api.wanteed-job.com/job/get-job?job_id=${jobID}`,
                {
                    method: 'GET',
                    headers: headers,
                }
            );
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching job:', error);
        }
    }, [token]);

    const getJobs = useCallback(async () => {
        try {
            console.log('Appel à getJobs');
            let url = 'https://dev.api.wanteed-job.com/job/get-jobs?from_id=' + from + '&to_id=' + to;

            if (domain !== 'all') url += '&domain_id=' + domain;
            if (company !== 'all') url += '&company_id=' + company;
            if (locationCity !== 'all') url += '&city=' + locationCity;
            if (locationCountry !== 'all') url += '&country=' + locationCountry;
            if (jobType !== 'all') url += '&type=' + jobType;

            // if token put the session-key
            let headers = {
                'Content-Type': 'application/json',
            };

            if (token) {
                headers['session-key'] = token;
            }

            const response = await fetch(url, {
                method: 'GET',
                headers: headers,
            });

            const data = await response.json();

            const jobPromises = data.map(async (jobID) => {
                return await getIndividualJob(jobID);
            });
            const newJobs = await Promise.all(jobPromises);

            setJobs(newJobs);

            if (newJobs.length === 10) {
                setNextButton(true);
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching jobs:', error);
            setLoading(false);
        }
    }, [from, to, domain, company, locationCity, locationCountry, jobType, token, getIndividualJob]);

    const getJobsFavorites = useCallback(async () => {
        try {
            if (!token) {
                return console.error('No token found. Cannot fetch favorites.');
            }

            const response = await fetch('https://dev.api.wanteed-job.com/job/get-favorites', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                }
            });
            const data = await response.json();

            const jobPromises = data.map(async (jobID) => {
                return await getIndividualJob(jobID);
            });
            const jobs = await Promise.all(jobPromises);

            setJobs(jobs);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching jobs:', error);
            setLoading(false);
        }
    }, [getIndividualJob, token]);

    const getJobsApplied = useCallback(async () => {
        try {
            if (!token) {
                return console.error('No token found. Cannot fetch applied jobs.');
            }

            const response = await fetch('https://dev.api.wanteed-job.com/job/get-canditate', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                }
            });
            const data = await response.json();

            const jobPromises = data.map(async (jobID) => {
                return await getIndividualJob(jobID);
            });
            const jobs = await Promise.all(jobPromises);

            setJobs(jobs);
            setLoading(false);
        }
        catch (error) {
            console.error('Error fetching jobs:', error);
            setLoading(false);
        }
    }, [getIndividualJob, token]);

    useEffect(() => {
        const getLabels = async () => {
            try {
                // Fetch job IDs from /getjobs endpoint
                const response = await fetch('https://dev.api.wanteed-job.com/job/get-filter-label', {
                    method: 'GET',
                });
                const data = await response.json();
                setLabels(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching jobs:', error);
                setLoading(false);
            }
        }
        getLabels();
    }, []);

    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const response = await fetch('https://dev.api.wanteed-job.com/job/get-domains', {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setDomains(data);
                    setCategories(Object.keys(data));
                } else {
                    console.error('Failed to fetch domains');
                }
            } catch (error) {
                console.error('Error fetching domains:', error);
            }
        };

        fetchDomains();
    }, []);

    const handleNextJobs = () => {
        setFrom((prevFrom) => prevFrom + 10);
        setTo((prevTo) => prevTo + 10);
    };

    const handlePreviousJobs = () => {
        setFrom((prevFrom) => prevFrom - 10);
        setTo((prevTo) => prevTo - 10);
    };

    // Filter jobs based on the search term
    const filteredJobs = jobs
        .filter(job => {
            return (job.title.toLowerCase().includes(searchTerm.toLowerCase()))
        })
        .sort((a, b) => {
            if (filter === 'recent') return new Date(b.release_date) - new Date(a.release_date);
            if (filter === 'old') return new Date(a.release_date) - new Date(b.release_date);
            if (filter === 'high') return b.score - a.score;
            if (filter === 'low') return a.score - b.score;
            return 0; // No sorting
        });

    useEffect(() => {
        const fetchJobsByTab = async () => {
            setJobs([]);
            if (activeTab === 'available') {
                await getJobs();
            } else if (activeTab === 'favorites') {
                await getJobsFavorites();
            } else if (activeTab === 'applied') {
                await getJobsApplied();
            }
        };

        fetchJobsByTab();
    }, [activeTab, getJobs, getJobsFavorites, getJobsApplied]);

    if (loading) {
        return <p>Chargement...</p>;
    }

    return (
        <div className={styles["jobs-container"]}>
            <Tabs
                selected={activeTab === 'available' ? 0 : activeTab === 'favorites' ? 1 : 2}
                onChange={(index) => {
                    let newTab;
                    if (index === 0) {
                        newTab = 'available';
                    } else if (index === 1) {
                        newTab = 'favorites';
                    } else if (index === 2) {
                        newTab = 'applied';
                    }
                    setActiveTab(newTab);
                }}
                tabsStyle={{ width: '90%' }}
                ulStyle={{ textAlign: 'center' }}
            >
                <Panel title="Offres Disponibles" onChange={() => setActiveTab('available')}>
                    <h1>Offres disponibles</h1>
                    <div className={styles["filter-bar"]}>
                        <select value={jobType} onChange={(e) => setJobType(e.target.value)} style={{ margin: '0' }}>
                            <option value="all">Type de contrat</option>
                            {labels.type.map((type) => (
                                <option key={type} value={type}>{type}</option>
                            ))}
                        </select>
                        <select value={locationCity} onChange={(e) => setLocationCity(e.target.value)}>
                            <option value="all">Ville</option>
                            {labels.city.map((city) => (
                                <option key={city} value={city}>{city}</option>
                            ))}
                        </select>
                        <select value={locationCountry} onChange={(e) => setLocationCountry(e.target.value)}>
                            <option value="all">Pays</option>
                            {labels.country.map((country) => (
                                <option key={country} value={country}>{country}</option>
                            ))}
                        </select>
                        <select value={company} onChange={(e) => setCompany(e.target.value)}>
                            <option value="all">Entreprise</option>
                            {labels.companies.map((company) => (
                                <option key={company.name} value={company.company_id}>{company.name}</option>
                            ))}
                        </select>
                        <select name="domain_id" value={domain} onChange={(e) => setDomain(e.target.value)}>
                            <option value="all">Domaine</option>
                            {categories.map((category) => (
                                <optgroup key={category} label={category}>
                                    {domains[category].map((domain) => (
                                        <option key={domain.id} value={domain.id}>
                                            {domain.profession}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </select>
                        <button onClick={() => getJobs()}>Chercher</button>
                    </div>
                </Panel>
                <Panel title="Offres Favorites" onChange={() => setActiveTab('favorites')}>
                    <h1>Offres favorites</h1>
                </Panel>
                <Panel title="Offres Postulées" onChange={() => setActiveTab('applied')}>
                    <h1>Offres postulées</h1>
                </Panel>
            </Tabs>
            <div className={styles["sort-bar"]}>
                <input
                    type="text"
                    placeholder="Chercher une offre..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <select name="filter" value={filter} onChange={(e) => setfilter(e.target.value)}>
                    <option value="recent">Plus récentes</option>
                    <option value="old">Plus anciennes</option>
                    <option value="high">Score élevé</option>
                    <option value="low">Score bas</option>
                </select>
            </div>
            <div className={styles["separator"]}>
            </div>
            <div className={styles["job-card-list"]}>
                {filteredJobs.length === 0 && <p>Aucune offre trouvée</p>}
                {filteredJobs.map((job) => (
                    // log the job
                    <JobCard
                        key={job.job_id}
                        id={job.job_id}
                        title={job.title}
                        company={job.company}
                        salary={job.salary}
                        date={new Date(job.release_date).toLocaleDateString('fr-FR')}
                        location={job.city + ", " + job.country}
                        type={job.type}
                        link={job.link}
                        description={job.description}
                        fav={job.favorite}
                        userSimilarJob={job.score}
                        candidated={job.candidate}
                    />
                ))}
            </div>
            {nextButton && (
                <>
                    <div className={styles["separator"]}></div>
                    {from > 0 && (
                        <button onClick={handlePreviousJobs} className={styles["previous-button"]}>
                            Voir moins
                        </button>
                    )}
                    <button onClick={handleNextJobs} className={styles["next-button"]}>
                        Voir plus
                    </button>
                </>
            )}
        </div>
    );
}

export default Jobs;
