import { Chart, RadialLinearScale, PointElement, LineElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import ExperienceList from '../components/ExperienceList';
import styles from '../styles/ExperiencePage.module.css';

Chart.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
);

const ExperiencePage = () => {
    return (
        <div className={styles["experience-page"]}>
            <div className={styles["experience-container"]}>
                <h1>Vos experiences</h1>
                <ExperienceList />
            </div>
        </div>
    );
};

export default ExperiencePage;
