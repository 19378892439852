import React, { useState, useEffect, useCallback } from 'react';
import { getSessionToken, getUserType } from '../utils/Auth';
import styles from '../styles/AdminCompanyPage.module.css';
import Modal from '../components/Modal';

// Modal for adding a new job offer
import JobModal from '../components/JobModal';
import AdminCulture from './AdminCulture';

import GenericToggleButton from "../components/GenericToggleButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const AdminCompanyPage = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [jobIdToDelete, setJobIdToDelete] = useState(null);

    const [isModalRemoveOpen, setIsModalRemoveOpen] = useState(false);
    const [userIdToRemove, setUserIdToRemove] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const token = getSessionToken();
    const [expandedJobId, setExpandedJobId] = useState(null);
    const [sortedUsers, setSortedUsers] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    const [users, setUsers] = useState([]);

    const [view, setView] = useState('Offres');

    const [emailToAdd, setEmailToAdd] = useState('');
    const [userType, setUserType] = useState(null);
    getUserType().then((type) => setUserType(type));

    // Fetch jobs for the selected company

    const fetchJobs = useCallback(async (companyId) => {
        try {
            const response = await fetch(`https://dev.api.wanteed-job.com/company/get-jobs?company_id=${companyId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                }
            });
            if (response.ok) {
                const data = await response.json();
                setJobs(data);
            } else {
                console.error('Failed to fetch jobs');
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    }, [token]);

    // Fetch companies associated with the user
    const fetchCompanies = useCallback(async () => {
        try {
            const response = await fetch('https://dev.api.wanteed-job.com/company/get-user-companies', {
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                }
            });
            if (response.ok) {
                const data = await response.json();
                setCompanies(data);
                if (data.length === 1) {
                    setSelectedCompany(data[0]); // Auto-select if only one company
                    fetchJobs(data[0].company_id);
                }
            } else {
                console.error('Failed to fetch companies');
            }
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    }, [token, fetchJobs]);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);


    const fetchUsers = async () => {
        if (selectedCompany) {
            fetch(`https://dev.api.wanteed-job.com/company/get-company-users?company_id=${selectedCompany.company_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                }
            })
                .then(response => response.json())
                .then(data => {
                    setUsers(data);
                    setSortedUsers(data);
                })
                .catch(error => console.error('Error fetching users:', error));
        }
    }

    useEffect(() => {
        // Get users of selected company
        if (selectedCompany) {
            fetch(`https://dev.api.wanteed-job.com/company/get-company-users?company_id=${selectedCompany.company_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                }
            })
                .then(response => response.json())
                .then(data => {
                    setUsers(data);
                    setSortedUsers(data);
                })
                .catch(error => console.error('Error fetching users:', error));
        }
    }, [selectedCompany, token]);

    const sortColumn = (key) => {
        // Alterner entre ascendant et descendant 
        let direction = "asc";
        if (sortConfig.key === key) {
            if (sortConfig.direction === "asc") direction = "desc";
            else if (sortConfig.direction === "desc") {
                direction = null;
                setSortedUsers(users); // Revenir à l'ordre initial
                setSortConfig({ key: null, direction: null }); // Reset du tri
                return;
            }
        }
        setSortConfig({ key, direction });

        // Trier les utilisateurs
        const sortedData = [...sortedUsers].sort((a, b) => {
            if (a[key].toLowerCase() < b[key].toLowerCase()) return direction === "asc" ? 1 : -1;
            if (a[key].toLowerCase() > b[key].toLowerCase()) return direction === "asc" ? -1 : 1;
            return 0;
        });
        setSortedUsers(sortedData);
    };

    // Handle company selection
    const handleCompanySelect = (event) => {
        const companyId = event.target.value;
        const company = companies.find(c => c.company_id === companyId);
        setSelectedCompany(company);
        fetchJobs(company.company_id);
    };

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filter jobs based on the search term
    const filteredJobs = jobs.filter(job =>
        job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        new Date(job.release_date).toLocaleDateString('fr-FR').includes(searchTerm)
    );

    // Open the modal to add a new job
    const openModal = () => setIsModalOpen(true);

    // Close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setIsModalDeleteOpen(false);
        setIsModalRemoveOpen(false);
        setUserIdToRemove(null);
        setJobIdToDelete(null);
    };

    const closeEditModal = () => setExpandedJobId(null);

    const deleteJob = async () => {
        try {
            const response = await fetch(`https://dev.api.wanteed-job.com/company/remove-job`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                },
                body: JSON.stringify({ company_id: selectedCompany.company_id, job_id: jobIdToDelete })
            });
            if (response.ok) {
                fetchJobs(selectedCompany.company_id);
            } else {
                console.error('Failed to delete job');
            }
        } catch (error) {
            console.error('Error deleting job:', error);
        }
    }

    const handleJobDeleteClick = (jobId) => {
        setJobIdToDelete(jobId);
        setIsModalDeleteOpen(true);
    };

    const toggleExpand = (jobId) => {
        if (expandedJobId === jobId) {
            setExpandedJobId(null);  // Ferme le panneau si déjà ouvert
        } else {
            setExpandedJobId(jobId);  // Ouvre le panneau pour le job sélectionné
        }
    };

    const addUser = async (email) => {
        fetch('https://dev.api.wanteed-job.com/company/add-user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'session-key': token
            },
            body: JSON.stringify({ company_id: selectedCompany.company_id, email_to_add: email })
        })
            .then(response => response.json())
            .then(data => {
                console.log('User added successfully');
                fetchUsers();
                setEmailToAdd('');
            })
            .catch(error => console.error('Error adding user:', error));
    };

    const handleRemoveUserClick = (user_id) => {
        setUserIdToRemove(user_id);
        setIsModalRemoveOpen(true);
    };

    const removeUser = async () => {

        setIsModalRemoveOpen(false);

        try {
            const response = await fetch(`https://dev.api.wanteed-job.com/company/remove-user?company_id=${selectedCompany.company_id}&user_id=${userIdToRemove}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                }
            });
            if (response.ok) {
                fetchUsers();
            } else {
                console.error('Failed to remove user');
            }
        } catch (error) {
            console.error('Error removing user:', error);
        }
    };

    const toggleButtons = [
        { label: 'Offres', view: 'Offres' },
        { label: 'Utilisateurs', view: 'Utilisateurs' },
        { label: 'Culture d\'entreprise', view: 'Culture d\'entreprise' }
    ];

    return (
        <div className={styles["admin-company-page"]}>
            <h1>Admin entreprise</h1>

            {/* Company Dropdown */}
            {companies.length > 1 && (
                <select value={selectedCompany?.company_id || ''} onChange={handleCompanySelect}>
                    <option value="" disabled>Selectionner l'entreprise</option>
                    {companies.map(company => (
                        <option key={company.company_id} value={company.company_id}>
                            {company.company_name}
                        </option>
                    ))}
                </select>
            )}

            <GenericToggleButton view={view} setView={setView} buttons={toggleButtons} />

            {view === 'Offres' && selectedCompany && (
                <>
                    {/* Search Bar and Add Job Button */}
                    <div className={styles["job-management-header"]}>
                        <input
                            type="text"
                            placeholder="Chercher une offre..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <button onClick={openModal} className="add-job-button">Créer un poste</button>
                    </div>

                    {isModalOpen && (
                        <div className={styles["modal-new-post"]}>
                            <div className={styles["edit-panel"]}>
                                <JobModal
                                    selectedCompany={selectedCompany}
                                    closeModal={closeModal}
                                    fetchJobs={fetchJobs}
                                />
                            </div>
                        </div>
                    )}

                    {expandedJobId && (
                        <div className={styles["modal-new-post"]}>
                            <div className={styles["edit-panel"]}>
                                <JobModal
                                    selectedCompany={selectedCompany}
                                    closeModal={closeEditModal}
                                    fetchJobs={fetchJobs}
                                    edit={true}
                                    data={jobs.find(job => job.job_id === expandedJobId)}
                                />
                            </div>
                        </div>
                    )}

                    <div className={styles["job-list"]}>
                        {filteredJobs.map(job => (
                            expandedJobId !== job.job_id &&
                            <div key={job.job_id} className={styles["job-item"]}
                                onClick={() => window.location.href = `/admin/job?jobId=${job.job_id}`}
                            >

                                {/* Afficher les informations du job uniquement si ce n'est pas le job en cours d'édition */}
                                <>
                                    <span>{job.title}</span>
                                    <span className={styles["date-span"]}>{new Date(job.release_date).toLocaleDateString('fr-FR')}</span>
                                    <button className={styles["edit-button"]} onClick={(e) => { e.stopPropagation(); toggleExpand(job.job_id) }}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <button className={styles["delete-button"]} onClick={(e) => { e.stopPropagation(); handleJobDeleteClick(job.job_id) }}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </>
                            </div>

                        ))}
                    </div>
                    <Modal
                        isOpen={isModalDeleteOpen}
                        onClose={closeModal}
                        contentLabel="confirm-delete-job"
                        className={styles["modal"]}
                        overlayClassName={styles["overlay"]}
                    >
                        <h2 className={styles["modal-title"]}>Vous êtes sûr de vouloir supprimer cette offre ?</h2>
                        <div className={styles["modal-buttons"]}>
                            <button onClick={deleteJob} style={{ backgroundColor: '#dc3545' }}>Oui</button>
                            <button onClick={closeModal}>Non</button>
                        </div>
                    </Modal>
                </>
            )
            }

            {
                view === 'Utilisateurs' && (
                    <div className={styles["user-management-container"]}>
                        <h2>Gestion des utilisateurs</h2>
                        {userType === 'admin' && (
                            <div className={styles["user-management"]}>
                                <h3>Ajouter un utilisateur à l'entreprise</h3>
                                <input className={styles["user-mail"]} type="email" placeholder="Email de l'utilisateur" onChange={(e) => setEmailToAdd(e.target.value)} />
                                <button className={styles["add-button"]} onClick={() => addUser(emailToAdd)} >
                                    Ajouter</button>
                            </div>
                        )}
                        <table className={styles["user-table"]}>
                            <thead>
                                <tr>
                                    <th
                                        onClick={() => sortColumn("first_name")}
                                        className={
                                            sortConfig.key === "first_name"
                                                ? sortConfig.direction === "asc"
                                                    ? styles["sorted-asc"]
                                                    : styles["sorted-desc"]
                                                : ""
                                        }
                                    >
                                        Prénom
                                    </th>
                                    <th
                                        onClick={() => sortColumn("last_name")}
                                        className={
                                            sortConfig.key === "last_name"
                                                ? sortConfig.direction === "asc"
                                                    ? styles["sorted-asc"]
                                                    : styles["sorted-desc"]
                                                : ""
                                        }
                                    >
                                        Nom
                                    </th>
                                    <th
                                        onClick={() => sortColumn("email")}
                                        className={
                                            sortConfig.key === "email"
                                                ? sortConfig.direction === "asc"
                                                    ? styles["sorted-asc"]
                                                    : styles["sorted-desc"]
                                                : ""
                                        }
                                    >
                                        Email
                                    </th>
                                    <th
                                        onClick={() => sortColumn("permission")}
                                        className={
                                            sortConfig.key === "permission"
                                                ? sortConfig.direction === "asc"
                                                    ? styles["sorted-asc"]
                                                    : styles["sorted-desc"]
                                                : ""
                                        }
                                    >
                                        Rôle
                                    </th>
                                    {userType === 'admin' &&
                                        <th></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(sortedUsers) &&
                                    sortedUsers.map((user, index) => (
                                        <tr key={index} className={styles["user-row"]}>
                                            <td>{user.first_name}</td>
                                            <td>{user.last_name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.permission === "ADMIN" ? "Admin" : "Utilisateur"}</td>
                                            {userType === 'admin' &&
                                                <td>
                                                    <button className={styles["delete-button"]} onClick={(e) => { e.stopPropagation(); handleRemoveUserClick(user.user_id) }}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            }
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <Modal
                            isOpen={isModalRemoveOpen}
                            onClose={closeModal}
                            contentLabel="confirm-remove-user"
                            className={styles["modal"]}
                            overlayClassName={styles["overlay"]}
                        >
                            <h2 className={styles["modal-title"]}>Vous êtes sûr de vouloir supprimer cet utilisateur ?</h2>
                            <div className={styles["modal-buttons"]}>
                                <button onClick={removeUser} style={{ backgroundColor: '#dc3545' }}>Oui</button>
                                <button onClick={closeModal}>Non</button>
                            </div>
                        </Modal>
                    </div>
                )
            }

            {
                view === 'Culture d\'entreprise' && (
                    <AdminCulture company_id={selectedCompany.company_id} />
                )
            }
        </div >
    );
};

export default AdminCompanyPage;
