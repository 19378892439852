// src/pages/ViewJob/Stats.js
import React, { useEffect } from 'react';
import { Radar, Line } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getSessionToken } from '../utils/Auth';
import styles from '../styles/Stats.module.css';
import { useState } from 'react';
import Tabs from '../components/Tabs';
import Panel from '../components/Panel';

const Stats = ({ jobData }) => {
    const [candidateOrigins, setcandidateOrigins] = useState([]);
    const [statsCandidate, setstatsCandidate] = useState([]);

    const [links, setLinks] = useState([]);

    const [idealPersonna, setIdealPersonna] = useState([]);
    const [idealBehavior, setIdealBehavior] = useState([]);
    const [idealRelational, setIdealRelational] = useState([]);
    const [idealCognitive, setIdealCognitive] = useState([]);
    const [idealCulture, setIdealCulture] = useState([]);
    // const [idealMbti, setIdealMbti] = useState([]);
    const [idealHardskill, setIdealHardskill] = useState([]);
    const [newHardskill, setNewHardskill] = useState('');
    const [newHardskillTime, setNewHardskillTime] = useState('');

    const [newLinkOrigin, setNewLinkOrigin] = useState('');


    const sessionToken = getSessionToken();


    const formatDate = (dateString) => new Date(dateString).toLocaleDateString();

    useEffect(() => {
        fetch(`https://dev.api.wanteed-job.com/job-company/get-links?company_id=${jobData.company_id}&job_id=${jobData.job_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'session-key': sessionToken,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("links", data);
                const currentUrl = window.location.href;
                // Keep only the domain name
                const currentUrlParts = currentUrl.split('/');
                const currentUrlDomain = currentUrlParts[0] + '//' + currentUrlParts[2];
                data.map(link => link.url = currentUrlDomain + '/invite?linkId=' + link.link_id); // Add the domain name to the link
                setLinks(data.map(link => ({ name: link.origin, url: link.url, link_id: link.link_id })));
            })
    }, [jobData.company_id, jobData.job_id, sessionToken]);
    // Prepare the data for the chart
    const processDataForChart = (data) => {

        const colorsList = [
            "#FF9800", // Orange
            "#808080", // Gray
            "#4CAF50", // Gray
            "#007bff", // Primary
            "#FF6347", // Tomato
            "#FF1493", // DeepPink
            "#00CED1", // DarkTurquoise
            "#FFA500", // Orange
            "#1E90FF", // DodgerBlue
            "#7FFF00", // Chartreuse
            "#DC143C", // Crimson
            "#228B22", // ForestGreen
            "#00FF00", // Lime
            "#FF69B4", // HotPink
            "#4169E1", // RoyalBlue
            "#8B0000", // DarkRed
            "#FF00FF", // Magenta
            "#ADFF2F", // GreenYellow
            "#00FA9A", // MediumSpringGreen
            "#00FF7F", // SpringGreen
            "#9932CC", // DarkOrchid
            "#B22222", // FireBrick
            "#7B68EE", // MediumSlateBlue
            "#8A2BE2", // BlueViolet
            "#FF8C00", // DarkOrange
        ];

        const dates = [...new Set(data.map(d => formatDate(d.add_date)))]; // Extract unique dates
        const uniqueOrigins = [...new Set(data.map(d => d.origin))]; // Extract unique origins

        if (dates.length === 0 || uniqueOrigins.length === 0) {
            return {
                labels: [],
                datasets: [],
            };
        }

        const datasets = uniqueOrigins.map(origin => {
            const originData = dates.map(date => {
                const filteredData = data.filter(d => formatDate(d.add_date) === date && d.origin === origin);
                return filteredData.reduce((sum, d) => sum + d.candidate_count, 0);
            });

            return {
                label: origin,
                data: originData,
                fill: false,
                borderColor: colorsList[uniqueOrigins.indexOf(origin) % colorsList.length],
                tension: 0.3,
            };
        });

        const maxYValue = Math.max(...datasets.flatMap(dataset => dataset.data));

        return {
            labels: dates,
            datasets: datasets,
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        max: maxYValue + 1,
                        ticks: {
                            stepSize: 1,
                        }
                    },
                },
            },
        };
    };

    useEffect(() => {
        fetch(`https://dev.api.wanteed-job.com/candidate/get-job-stats?company_id=${jobData.company_id}&job_id=${jobData.job_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'session-key': sessionToken,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("data", data);
                let candidateOrigins = [];
                setstatsCandidate(data);

                for (let i = 0; i < data.length; i++) {
                    const origin = data[i].origin;
                    const count = data[i].candidate_count;

                    if (candidateOrigins[origin]) {
                        candidateOrigins[origin] += count;
                    } else {
                        candidateOrigins[origin] = count;
                    }
                }
                setcandidateOrigins(candidateOrigins);
            })
    }, [jobData.company_id, jobData.job_id, sessionToken]);

    useEffect(() => {
        fetch(`https://dev.api.wanteed-job.com/job-company/get-personna?company_id=${jobData.company_id}&job_id=${jobData.job_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'session-key': sessionToken,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("ideal candidate", data);
                setIdealPersonna(data.personna.filter(trait => ['B', 'C', 'R'].includes(trait.trait_id)));
                setIdealBehavior(data.personna.filter(trait => parseInt(trait.trait_id) >= 13 && parseInt(trait.trait_id) <= 21));
                setIdealRelational(data.personna.filter(trait => parseInt(trait.trait_id) >= 22 && parseInt(trait.trait_id) <= 31));
                setIdealCognitive(data.personna.filter(trait => parseInt(trait.trait_id) >= 32 && parseInt(trait.trait_id) <= 39));
                setIdealCulture(data.personna.filter(trait => parseInt(trait.trait_id) >= 1 && parseInt(trait.trait_id) <= 12));
                // setIdealMbti(data.personna.filter(trait => ['E', 'F', 'I', 'J', 'N', 'P', 'S', 'T'].includes(trait.trait_id)));
                setIdealHardskill(data.hardskill);
            })
    }, [jobData.company_id, jobData.job_id, sessionToken, statsCandidate]);

    const chartData = processDataForChart(statsCandidate);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const totalCandidates = Object.values(candidateOrigins).reduce((sum, count) => sum + count, 0);

    const radarDataPersona = {
        labels: idealPersonna.length > 0 ? idealPersonna.map(trait => trait.trait_name) : [],
        datasets: idealPersonna.length > 0 ? [
            {
                label: idealPersonna[0].type,
                data: idealPersonna.map(trait => trait.value),
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: 'rgba(75,192,192,1)',
                pointBorderColor: '#fff',
            },
        ] : [],
    };

    const radarDataBehaviour = {
        labels: idealBehavior.length > 0 ? idealBehavior.map(trait => trait.trait_name) : [],
        datasets: idealBehavior.length > 0 ? [
            {
                label: idealBehavior[0].type,
                data: idealBehavior.map(trait => trait.value),
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointBorderColor: '#fff',
            },
        ] : [],
    };


    const radarDataRelational = {
        labels: idealRelational.length > 0 ? idealRelational.map(trait => trait.trait_name) : [],
        datasets: idealRelational.length > 0 ? [
            {
                label: idealRelational[0].type,
                data: idealRelational.map(trait => trait.value),
                backgroundColor: 'rgba(255,206,86,0.2)',
                borderColor: 'rgba(255,206,86,1)',
                pointBackgroundColor: 'rgba(255,206,86,1)',
                pointBorderColor: '#fff',
            },
        ] : [],
    };


    const radarDataCognitive = {
        labels: idealCognitive.length > 0 ? idealCognitive.map(trait => trait.trait_name) : [],
        datasets: idealCognitive.length > 0 ? [
            {
                label: idealCognitive[0].type,
                data: idealCognitive.map(trait => trait.value),
                backgroundColor: 'rgba(54,162,235,0.2)',
                borderColor: 'rgba(54,162,235,1)',
                pointBackgroundColor: 'rgba(54,162,235,1)',
                pointBorderColor: '#fff',
            },
        ] : [],
    };


    const radarDataCulture = {
        labels: idealCulture.length > 0 ? idealCulture.map(trait => trait.trait_name) : [],
        datasets: idealCulture.length > 0 ? [
            {
                label: idealCulture[0].type,
                data: idealCulture.map(trait => trait.value),
                backgroundColor: 'rgba(153,102,255,0.2)',
                borderColor: 'rgba(153,102,255,1)',
                pointBackgroundColor: 'rgba(153,102,255,1)',
                pointBorderColor: '#fff',
            },
        ] : [],
    };

    const radarOptions = {
        scales: {
            r: {
                min: 0,
                max: 10,
                ticks: {
                    stepSize: 1,
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.raw !== null) {
                            label += context.raw;
                        }
                        return label;
                    },
                },
            },
            legend: {
                labels: {
                    font: {
                        size: 20, // Taille de la légende
                        weight: 'bold', // Optionnel : Rendre la légende en gras
                    },
                },
            },
        },
    };


    // const mbtiTraitPairs = [
    //     ['E', 'I'],
    //     ['S', 'N'],
    //     ['T', 'F'],
    //     ['J', 'P'],
    // ];

    // const mbtiTraitPairsLabels = mbtiTraitPairs.map(pair => {
    //     console.log("idealMbti", idealMbti);
    //     if (idealMbti.length === 0) {
    //         return '';
    //     }
    //     const traitA = idealMbti.find(trait => trait.trait_id === pair[0]);
    //     const traitB = idealMbti.find(trait => trait.trait_id === pair[1]);
    //     return `${traitA.trait_name} / ${traitB.trait_name}`;
    // });

    // const mbtiValuesA = mbtiTraitPairs.map(pair => {
    //     const trait = idealMbti.find(trait => trait.trait_id === pair[0]);
    //     return trait ? trait.value : 0;
    // });
    // const mbtiValuesB = mbtiTraitPairs.map(pair => {
    //     const trait = idealMbti.find(trait => trait.trait_id === pair[1]);
    //     return trait ? trait.value : 0;
    // });

    // const bidirectionalData = {
    //     labels: mbtiTraitPairsLabels,
    //     datasets: [
    //         {
    //             data: mbtiValuesA.map(value => -value),
    //             backgroundColor: 'rgba(75,192,192,0.5)',
    //             borderColor: 'rgba(75,192,192,1)',
    //             borderWidth: 1,
    //         },
    //         {
    //             data: mbtiValuesB,
    //             backgroundColor: 'rgba(255,99,132,0.5)',
    //             borderColor: 'rgba(255,99,132,1)',
    //             borderWidth: 1,
    //         }
    //     ],
    // };

    // const bidirectionalOptions = {
    //     indexAxis: 'y',
    //     scales: {
    //         x: {
    //             min: -10,
    //             max: 10,
    //             beginAtZero: true,
    //             ticks: {
    //                 stepSize: 1,
    //             },
    //         },
    //         y: {
    //             stacked: true,
    //             ticks: {
    //                 stepSize: 1,
    //             },
    //         },
    //     },
    //     plugins: {
    //         tooltip: {
    //             callbacks: {
    //                 label: function (context) {
    //                     let label = context.dataset.label || '';
    //                     if (label) {
    //                         label += ': ';
    //                     }
    //                     if (context.raw !== null) {
    //                         label += Math.abs(context.raw);
    //                     }
    //                     return label;
    //                 },
    //             },
    //         },
    //         legend: {
    //             display: false, // Pour enlever la légende
    //         },
    //     },
    // };

    const originsOptions = chartData.options;

    // const chartRefs = {
    //     cognitive: useRef(null),
    //     behavior: useRef(null),
    //     relational: useRef(null),
    // };

    // const scrollToChart = (ref) => {
    //     if (ref && ref.current) {
    //         ref.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    //     }
    // };

    function formatTimeInMonths(months) {
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;
        if (years > 0 && remainingMonths > 0) {
            return `${years} an${years > 1 ? 's' : ''} et ${remainingMonths} mois`;
        }
        if (years > 0) {
            return `${years} an${years > 1 ? 's' : ''}`;
        }
        return `${months} mois`;
    }

    function firstLetterToUppercase(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function toLowerCase(string) {
        return string.toLowerCase();
    }

    const addLink = (origin) => {
        fetch(`https://dev.api.wanteed-job.com/job-company/create-job-link`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'session-key': sessionToken,
            },
            body: JSON.stringify({
                company_id: jobData.company_id,
                job_id: jobData.job_id,
                origin: origin,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("data", data);
                setLinks([...links, { name: origin, url: data, link_id: data.link_id }]);
            })
    }

    const addHardskill = (hardskill, time) => {
        // Ajouter le nouveau hardskill à la liste
        fetch(`https://dev.api.wanteed-job.com/job-company/add-hardskill`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'session-key': sessionToken,
            },
            body: JSON.stringify({
                company_id: jobData.company_id,
                time: time,
                job_id: jobData.job_id,
                hardskill: hardskill,
            }),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log("data", data);
            setIdealHardskill([...idealHardskill, { hardskill, time }]);
        });
        setNewHardskill('');
        setNewHardskillTime('');
    };

    const removeHardskill = (hardskill) => {
        console.log("hardskill", hardskill);
        fetch(`https://dev.api.wanteed-job.com/job-company/remove-hardskill`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'session-key': sessionToken,
            },
            body: JSON.stringify({
                company_id: jobData.company_id,
                job_id: jobData.job_id,
                hardskill_id: hardskill.hardskill_id,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("data", data);
                setIdealHardskill(idealHardskill.filter(skill => skill !== hardskill));
            })
    }

    const handleSuppress = (link_id) => {
        console.log("link_id", link_id);
        fetch(`https://dev.api.wanteed-job.com/job-company/remove-job-link?company_id=${jobData.company_id}&link_id=${link_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'session-key': sessionToken,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("data", data);
                setLinks(links.filter(link => link.link_id !== link_id));
            })
    }

    return (
        <div className={styles["stats-container"]}>
            <Tabs>
                <Panel title="Provenance des candidats">
                    <div className={styles["candidate-stats"]}>
                        <h2>Provenance des candidats</h2>
                        <div className={styles["candidate-stats-inner"]}>
                            <div className={styles["chart-container"]}>
                                {chartData.datasets.length > 0 ? ( // Check if there is data to render
                                    <Line data={chartData} options={originsOptions} />
                                ) : (
                                    <p>Pas de données disponibles. <br />Créez des liens de candidature pour tracer les provenances des candidats.</p>
                                )}

                            </div>
                            <div className={styles["candidate-stats-details"]}>
                                <p><strong>Nombre total de candidats :</strong> {totalCandidates}</p>
                                <p><strong>Provenances des candidats :</strong></p>
                                <div className={styles["candidate-origin-list"]}>
                                    {Object.entries(candidateOrigins).map(([origin, count], index) => (
                                        <div key={index} className={styles["candidate-origin-item"]}><strong>{origin} :</strong> {count}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel title="Lien de candidature">
                    <div className={styles["candidate-links"]}>
                        <h2>Partager des liens de candidature</h2>
                        <p>Partagez ces liens avec vos candidats pour les inviter à postuler à ce poste.<br />Nommez chaque lien pour suivre les candidatures provenant de chaque source.</p>
                        <div className={styles["candidate-links-list"]}>
                            {links.map((link, index) => (
                                <div key={index} className={styles["candidate-links-item"]}>
                                    <p>{link.name}</p>
                                    <div className={styles["candidate-links-link"]}>
                                        <input type="text" value={link.url} readOnly />
                                        <button onClick={() => copyToClipboard(link.url)}><FontAwesomeIcon icon={faCopy} /></button>
                                    </div>
                                    <i>
                                        <button className={styles["candidate-suppress-link"]} onClick={() => handleSuppress(link.link_id)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </i>
                                </div>
                            ))}
                            <div className={styles["candidate-links-item"]}>
                                <p>Ajouter un lien</p>
                                <div className={styles["candidate-links-link"]}>
                                    <input type="text" placeholder="Nom du lien" onChange={(e) => setNewLinkOrigin(e.target.value)} />
                                </div>
                                <button onClick={() => addLink(newLinkOrigin)}>+</button>
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel title="Competences requises">
                    <div className={styles["job-hardskills"]}>
                        <h2>Compétences requises pour la fiche de poste</h2>
                        <div className={styles["job-hardskills-list"]}>
                            {idealHardskill.map((hardskill, index) => (
                                    <div key={index} className={styles["job-hardskills-item"]}>
                                        <p>{firstLetterToUppercase(hardskill.hardskill)}, ({formatTimeInMonths(hardskill.time)})</p>
                                        <button onClick={() => removeHardskill(hardskill)}>-</button>
                                    </div>
                        
                            ))}
                            <div className={styles["job-hardskills-item"]}>
                                <input 
                                    type="text" 
                                    placeholder="Ajouter un hardskill" 
                                    value={newHardskill}
                                    onChange={(e) => setNewHardskill(e.target.value)} 
                                />
                                <input 
                                    type="number" 
                                    placeholder="Durée en mois" 
                                    value={newHardskillTime}
                                    onChange={(e) => setNewHardskillTime(e.target.value)} 
                                />
                                <button onClick={() => addHardskill(toLowerCase(newHardskill), newHardskillTime)}>+</button>
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel title="Qualités attendues">
                    <div className={styles["persona-analysis"]}>
                        <h2>Qualités attendues pour le candidat idéal</h2>
                        <div className={styles["radar-charts"]}>
                            <div className={styles["radar-chart"]}>
                                <Radar data={radarDataPersona} options={radarOptions} />
                            </div>
                            <div className={styles["radar-chart"]}>
                                <Radar data={radarDataBehaviour} options={radarOptions} />
                            </div>
                            <div className={styles["radar-chart"]}>
                                <Radar data={radarDataRelational} options={radarOptions} />
                            </div>
                            <div className={styles["radar-chart"]}>
                                <Radar data={radarDataCognitive} options={radarOptions} />
                            </div>
                            <div className={styles["radar-chart"]}>
                                <Radar data={radarDataCulture} options={radarOptions} />
                            </div>
                            {/*<Bar data={bidirectionalData} options={bidirectionalOptions} className={styles["radar-chart"]} />}*/}
                        </div>
                        {/* <div className={styles["charts-container"]}>
                            <div className={styles["radar-chart-item"]}>
                                <div className={styles["radar-chart"]}>
                                    <Radar data={radarDataPersona} options={radarOptions} />
                                </div>
                            </div>

                            <div className={styles["delimitation"]} />

                            <div className={styles["persona-charts-container"]}>
                                <div className={styles["persona-charts-list"]}>
                                    <div ref={chartRefs.cognitive} className={styles["persona-charts-item"]}>
                                        <div className={styles["radar-chart"]}>
                                            <Radar data={radarDataCognitive} options={radarOptions} />
                                        </div>
                                        {idealBehavior && idealBehavior.length > 0 && (
                                            <div className={styles["persona-chart-next-prev"]}>
                                                <h4 onClick={() => scrollToChart(chartRefs.behavior)}>&lt; {idealBehavior[0].type}</h4>
                                                <h4 onClick={() => scrollToChart(chartRefs.relational)}>{idealRelational[0].type} &gt;</h4>
                                            </div>
                                        )}
                                    </div>
                                    <div ref={chartRefs.relational} className={styles["persona-charts-item"]}>
                                        <div className={styles["radar-chart"]}>
                                            <Radar data={radarDataRelational} options={radarOptions} />
                                        </div>
                                        {idealCognitive && idealCognitive.length > 0 && (
                                            <div className={styles["persona-chart-next-prev"]}>
                                                <h4 onClick={() => scrollToChart(chartRefs.cognitive)}>&lt; {idealCognitive[0].type}</h4>
                                                <h4 onClick={() => scrollToChart(chartRefs.behavior)}>{idealBehavior[0].type} &gt;</h4>
                                            </div>
                                        )}
                                    </div>
                                    <div ref={chartRefs.behavior} className={styles["persona-charts-item"]}>
                                        <div className={styles["radar-chart"]}>
                                            <Radar data={radarDataBehaviour} options={radarOptions} />
                                        </div>
                                        {idealRelational && idealRelational.length > 0 && (
                                            <div className={styles["persona-chart-next-prev"]}>
                                                <h4 onClick={() => scrollToChart(chartRefs.relational)}>&lt; {idealRelational[0].type}</h4>
                                                <h4 onClick={() => scrollToChart(chartRefs.cognitive)}>{idealCognitive[0].type} &gt;</h4>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={styles["delimitation"]} />

                            <div className={styles["radar-chart-item"]}>
                                <div className={styles["radar-chart"]}>
                                    <Radar data={radarDataCulture} options={radarOptions} />
                                </div>
                            </div>

                            <div className={styles["delimitation"]} />

                            {/* <div className={styles["radar-chart-item"]}>
                                <div className={styles["bar-chart"]}>
                                    <h4>Personnalité</h4>
                                    <Bar data={bidirectionalData} options={bidirectionalOptions} />
                                </div>
                            </div> */}
                        {/* </div> */}
                    </div>
                </Panel>
            </Tabs>
        </div>
    );
};

export default Stats;
